<script setup>
import Container from "./Container.vue";
import NavLink from "./NavLink.vue";
import LogoBlock from "./LogoBlock.vue";

const menuItems = [
    // {
    //     title: "Events",
    //     url: "/events",
    // },
    // {
    //     title: "Tracks",
    //     url: "/tracks",
    // },
    {
        title: "Contact us",
        route: "contact-us",
        external: false,
    },
    {
        title: "Pricing",
        route: "pricing",
        external: false,
    },
    {
        title: "FAQ",
        route: "faq",
        external: false,
    },
    {
        title: "Terms of Service",
        route: "terms.show",
        external: false,
    },
    {
        title: "Privacy Policy",
        route: "policy.show",
        external: false,
    },
    {
        title: "Roadbook Lexicon",
        url: "/faq/fim-fia-regulations-lexicon-19",
        external: true,
    },
];

function filteredItems(external) {
    return menuItems.filter((item) => {
        return item.external === external;
    });
}

import { ref, onMounted } from "vue";
import Cookies from "js-cookie";
import Button from "@/Components/Button.vue";

// Reactive reference to track whether the banner should be hidden
const cookieClosed = ref(false);

// Check if the cookie is already set when the component is mounted
onMounted(() => {
    if (Cookies.get("cookieBannerClosed")) {
        cookieClosed.value = true;
    }
});

// Set cookie and hide the banner on close
const closeBanner = () => {
    Cookies.set("cookieBannerClosed", "true", { expires: 356 });
    cookieClosed.value = true;
};

// Redirect to a Cookie Policy page
const goToCookiePolicy = () => {
    window.location.href = "/cookie-policy";
};
</script>

<template>
    <hr />
    <div class="bg-slate-50">
        <Container>
            <div class="pb-4 pt-8 text-center">
                <LogoBlock />
                <div class="mt-3 text-sm">
                    <div class="columns-sm sm:justify-center md:flex md:columns-1 md:space-x-10">
                        <NavLink
                            v-for="item in filteredItems(false)"
                            :href="route(item.route)"
                            :class="
                                route().current(item.route)
                                    ? 'bg-slate-200 text-slate-900 visited:text-slate-900 hover:bg-slate-200 hover:text-slate-900'
                                    : 'text-slate-700 hover:text-slate-900 visited:text-slate-700 hover:bg-slate-200'
                            "
                        >
                            {{ item.title }}
                        </NavLink>

                        <a
                            v-for="item in filteredItems(true)"
                            :key="item.url"
                            :href="item.url"
                            target="_blank"
                            class="inline-block rounded-lg px-2 py-1 text-sm font-normal text-slate-700 visited:text-slate-700 hover:bg-slate-200 hover:text-slate-900 hover:no-underline"
                        >
                            {{ item.title }}
                        </a>
                    </div>
                </div>
            </div>
            <div class="flex items-center justify-center space-x-4">
                <a
                    href="https://play.google.com/store/apps/details?id=com.roadbookrally.rbrr"
                    target="_blank"
                    aria-label="RBR Roadbook Reader app on Google Play"
                >
                    <img
                        src="/img/get-it-on-google-play.svg"
                        alt="Download on Google Play store"
                        class="h-12"
                    />
                </a>
                <!--                <a href="https://testflight.apple.com/join/AqAWJNJT">
                    <AppStore class="h-16" />
                </a>-->
            </div>

            <div
                class="mt-4 flex flex-col items-center border-t border-slate-400/10 py-10 sm:flex-row-reverse sm:justify-between"
            >
                <div class="flex gap-x-6">
                    <a
                        href="https://twitter.com/RoadbookRally"
                        target="_blank"
                        aria-label="RoadbookRally on Twitter"
                    >
                        <svg
                            aria-hidden="true"
                            class="size-6 fill-slate-500 group-hover:fill-slate-700"
                        >
                            <path
                                d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0 0 22 5.92a8.19 8.19 0 0 1-2.357.646 4.118 4.118 0 0 0 1.804-2.27 8.224 8.224 0 0 1-2.605.996 4.107 4.107 0 0 0-6.993 3.743 11.65 11.65 0 0 1-8.457-4.287 4.106 4.106 0 0 0 1.27 5.477A4.073 4.073 0 0 1 2.8 9.713v.052a4.105 4.105 0 0 0 3.292 4.022 4.093 4.093 0 0 1-1.853.07 4.108 4.108 0 0 0 3.834 2.85A8.233 8.233 0 0 1 2 18.407a11.615 11.615 0 0 0 6.29 1.84"
                            />
                        </svg>
                    </a>
                </div>
                <div class="mt-6 text-sm text-slate-500 sm:mt-0">
                    Copyright &copy; 2022 -
                    {{ new Date().getFullYear() }}
                    RoadbookRally.com All rights reserved.
                </div>
            </div>
        </Container>
        <!-- Cookie Banner -->
        <div
            v-if="!cookieClosed"
            id="cookie-banner"
            class="fixed inset-x-0 bottom-0 z-[200000] flex flex-col bg-gray-800 p-3 text-sm text-white md:flex-row md:items-center md:justify-between"
        >
            <p class="mb-2 md:mb-0 md:pr-4">
                Cookies: We use cookies to provide you with the best experience on our website. By using
                RoadbookRally.com, you agree to our use of cookies. If you do not agree, do not use
                RoadbookRally.com.
            </p>
            <div class="mt-2 flex justify-between space-x-4 md:mt-0">
                <div>
                    <Button
                        color="white"
                        variant="solid"
                        class="flex w-full items-center justify-center whitespace-nowrap rounded-md border border-transparent px-8 py-3 md:px-10 md:py-1"
                        @click="goToCookiePolicy"
                    >
                        Cookie Policy
                    </Button>
                </div>

                <div>
                    <Button
                        color="primary"
                        variant="solid"
                        class="flex w-full items-center justify-center rounded-md border border-transparent px-8 py-3 md:px-10 md:py-1"
                        @click="closeBanner"
                    >
                        Close
                    </Button>
                </div>
            </div>
        </div>
    </div>
</template>
